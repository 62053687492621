@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.bubble.css";
@import "~quill/dist/quill.snow.css";

/*Editor's Container*/

.ql-container.ql-snow {
  border-radius: 4px !important;
  margin-bottom: 8px !important;
  font-family: "Open Sans", sans-serif !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 20px !important;
  height: fit-content !important;
  overflow-y: auto !important;
}

/*Editor's Header*/

.ql-toolbar.ql-snow {
  border-radius: 4px !important;
  position: absolute !important;
  top: auto !important;
  left: 0 !important;
  z-index: 1 !important;
  width: 100% !important;
  height: 54px !important;
  display: flex !important;
  align-items: center !important;
}

.ql-toolbar.ql-snow,
.ql-container.ql-snow {
  background: var(--grayFA) !important;
  border: 1px solid var(--grayF1) !important;
}

/*Editor Header's buttons*/

.ql-snow.ql-toolbar button,
.ql-snow .ql-toolbar button {
  border-radius: 4px !important;

  &:hover,
  &:focus {
    background-color: var(--grayF1);
    color: var(--nrb-ob-color) !important;
  }

  &:hover .ql-fill,
  &:hover .ql-stroke.ql-fill,
  &:focus .ql-fill,
  &:focus .ql-stroke.ql-fill {
    fill: var(--nrb-ob-color) !important;
  }

  &:hover .ql-stroke,
  &:focus .ql-stroke {
    stroke: var(--nrb-ob-color) !important;
  }
}

.ql-snow.ql-toolbar button.ql-active,
.ql-snow .ql-toolbar button.ql-active {
  color: var(--nrb-ob-color) !important;
}

.ql-snow.ql-toolbar button.ql-active .ql-fill,
.ql-snow .ql-toolbar button.ql-active .ql-fill,
.ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill {
  fill: var(--nrb-ob-color) !important;
}

.ql-snow.ql-toolbar button.ql-active .ql-stroke,
.ql-snow .ql-toolbar button.ql-active .ql-stroke {
  stroke: var(--nrb-ob-color) !important;
}

.ql-snow .ql-tooltip a.ql-action::after,
.ql-snow .ql-tooltip a.ql-remove::before {
  color: white;
  padding-inline: 14px;
  border-radius: 4px;
  font-weight: 600 !important;
  font-size: 14px !important;
  margin-left: 4px !important;
}

/*Content area*/

.ql-editor {
  max-height: 500px !important;
  padding-top: 62px !important;
}

/*Create/Edit link field*/

.ql-snow .ql-tooltip,
.ql-tooltip.ql-editing {
  transform: translateY(-60px) !important;
  padding: 5px 10px !important;
  width: 100% !important;
  left: 0 !important;
  z-index: 999 !important;
  border-radius: 4px !important;
  border: none !important;
  height: 36px;

  &::before {
    content: "URL:" !important;
  }
}

.ql-tooltip.ql-editing > *,
.ql-snow .ql-tooltip a.ql-preview {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  outline: transparent;
  border-radius: 4px;
  width: 70% !important;
}

/*Link Removing/Editing input*/

.ql-snow .ql-tooltip a.ql-preview {
  left: 42%;
  border: 1px solid #ccc;
  padding-inline: 2px;
  max-width: 100%;
  margin-left: 4px;
}

/*Save link button*/

.ql-snow .ql-tooltip.ql-editing a.ql-action {
  &::after {
    position: absolute;
    top: 50%;
    right: -68px;
    transform: translateY(-50%);
    background-color: var(--nrb-secondary-color);
    color: white;
    padding: 0 14px;
    border-radius: 4px;
    font-weight: 600 !important;
    font-size: 14px !important;
  }

  &:hover::after {
    background-color: var(--nrb-secondary-dark-color);
  }

  /*Before the Link Content*/

  &::before {
    content: "Enter link:" !important;
    font-size: 14px !important;
    margin-inline: 0 2px !important;
    position: absolute;
    top: 50%;
    left: -70px;
    transform: translateY(-50%);
    color: #444;
    font-weight: normal;
    cursor: auto;
    pointer-events: none;
  }
}

/*Edit link button*/

.ql-snow .ql-tooltip a.ql-action {
  &::after {
    position: absolute;
    top: 50%;
    right: 104px;
    transform: translateY(-50%);
    color: black;
    background-color: var(--grayF1);
    margin-inline: 4px;
  }

  &:hover::after {
    color: white;
    background-color: #ccc;
  }
}

/*Remove link button*/

.ql-snow .ql-tooltip a.ql-remove {
  &::before {
    position: absolute;
    top: 50%;
    right: 2%;
    transform: translateY(-50%);
    color: white;
    padding: 0 14px;
    border-radius: 4px;
    font-weight: 600 !important;
    font-size: 14px !important;
    background-color: var(--nrb-ob-color);
  }

  &:hover::before {
    background-color: var(--nrb-ob-dark-color);
  }
}

.ql-snow a {
  color: var(--nrb-ob-color) !important;
}

.ql-snow .ql-tooltip[data-mode="link"]::before {
  content: "" !important;
  margin-inline: 0 !important;
}

.ql-editor.ql-blank {
  min-height: 330px !important;

  &::before {
    font-family: "Open Sans", sans-serif !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 20px !important;
    color: #a8a8a8 !important;
    padding-bottom: 24px !important;
  }
}

/*Content link styles*/

.ql-editor p a {
  color: var(--nrb-ob-color);
  font-weight: bold;
}

.ql-toolbar.ql-snow .ql-formats {
  margin-right: 0;
}

@media screen and (max-width: 1240px) {
  .ql-snow .ql-tooltip a.ql-preview {
    left: 38%;
    width: 58% !important;
  }

  .ql-snow .ql-tooltip a.ql-action::after {
    right: 98px;
  }
}

@media screen and (max-width: 1140px) {
  .ql-tooltip.ql-editing > * {
    width: 58% !important;
  }
}

@media screen and (max-width: 1100px) {
  .ql-tooltip.ql-editing > * {
    width: 58% !important;
  }
}

@media screen and (max-width: 1060px) {
  .ql-snow .ql-tooltip a.ql-preview {
    left: 36%;
    width: 52% !important;
  }
}

@media screen and (max-width: 960px) {
  .ql-snow .ql-tooltip a.ql-preview {
    width: 46% !important;
  }
}

@media screen and (max-width: 920px) {
  .ql-tooltip.ql-editing > * {
    width: 68% !important;
  }

  .ql-snow .ql-tooltip a.ql-preview {
    left: 52%;
    width: 44% !important;
  }

  .ql-snow .ql-tooltip::before {
    content: "" !important;
  }

  .ql-snow .ql-tooltip a.ql-action::after {
    right: 4%;
    width: fit-content;
  }

  .ql-snow .ql-tooltip a.ql-remove::before {
    left: 4%;
    right: auto;
  }

  .ql-snow .ql-tooltip.ql-editing a.ql-action::after {
    right: -56px;
    padding-inline: 8px;
  }

  .ql-snow .ql-tooltip.ql-editing a.ql-action::before {
    content: "Link:" !important;
    transform: translate(34px, -50%);
  }
}

@media screen and (max-width: 878px) {
  .ql-tooltip.ql-editing > * {
    width: 60% !important;
  }

  .ql-snow .ql-tooltip a.ql-preview {
    width: 42% !important;
  }
}

@media screen and (max-width: 840px) {
  .ql-editor p {
    width: 240px;
  }

  .ql-snow .ql-tooltip a.ql-preview {
    left: 53%;
    width: 34% !important;
  }
}

@media screen and (max-width: 760px) {
  .ql-tooltip.ql-editing > * {
    width: 54% !important;
  }

  .ql-snow .ql-tooltip a.ql-preview {
    left: 52%;
    width: 24% !important;
  }

  .ql-editor p {
    width: 200px;
  }
}

@media screen and (max-width: 700px) {
  .ql-snow .ql-tooltip.ql-editing a.ql-action::after {
    right: -48px;
    padding-inline: 4px;
  }
}

@media screen and (max-width: 680px) {
  .ql-editor p {
    width: 160px;
  }

  .ql-snow .ql-tooltip a.ql-preview {
    left: 54%;
    width: 16% !important;
  }
}

@media screen and (max-width: 648px) {
  .ql-toolbar.ql-snow {
    padding: 8px 0 !important;
  }
}

@media screen and (max-width: 600px) {
  .ql-editor p {
    width: 100%;
    max-width: 400px;
  }

  .ql-snow .ql-tooltip a.ql-preview {
    display: block;
    left: 52%;
    width: 50% !important;
  }

  .ql-tooltip.ql-editing {
    z-index: 9999 !important;
    border: none !important;
    border-radius: 4px !important;
    height: 36px;
  }

  .ql-tooltip.ql-editing > * {
    width: 60% !important;
  }

  .ql-snow .ql-tooltip.ql-editing a.ql-action::after {
    right: -68px;
    padding-inline: 14px;
  }

  .ql-snow .ql-tooltip.ql-editing a.ql-action::before {
    left: -70px;
  }

  .ql-toolbar.ql-snow {
    padding: 8px !important;
  }

  .ql-editor {
    max-height: 360px !important;
  }
}

@media screen and (max-width: 540px) {
  .ql-snow .ql-tooltip a.ql-preview {
    width: 42% !important;
  }

  .ql-snow .ql-tooltip a.ql-action::after {
    right: 4%;
  }

  .ql-snow .ql-tooltip a.ql-remove::before {
    left: 4%;
  }
}

@media screen and (max-width: 440px) {
  .ql-editor p {
    width: 260px;
  }

  .ql-snow .ql-tooltip a.ql-preview {
    width: 34% !important;
  }

  .ql-snow .ql-tooltip.ql-editing a.ql-action::after {
    right: -56px;
    padding-inline: 8px;
  }
}

@media screen and (max-width: 400px) {
  .ql-editor p {
    width: 200px;
  }
}

@media screen and (max-width: 380px) {
  .ql-tooltip.ql-editing > * {
    left: 4px;
    transform: translate(10%, -50%);
  }

  .ql-snow .ql-tooltip a.ql-preview {
    left: 54%;
    width: 32% !important;
  }

  .ql-snow .ql-tooltip.ql-editing a.ql-action::before {
    content: "" !important;
  }
}

@media screen and (max-width: 360px) {
  .ql-snow .ql-tooltip a.ql-preview {
    width: 42% !important;
  }

  .ql-snow .ql-tooltip a.ql-action::after {
    right: 2%;
    padding-inline: 3px;
  }

  .ql-snow .ql-tooltip a.ql-remove::before {
    left: 2%;
    padding-inline: 3px;
  }
}

@media screen and (max-width: 340px) {
  .ql-toolbar.ql-snow {
    display: block !important;
    height: 64px !important;
    padding-right: 44px !important;
  }
}

@media screen and (max-width: 320px) {
  .ql-snow .ql-tooltip a.ql-preview {
    display: none;
  }

  .ql-snow .ql-tooltip a.ql-action::after {
    padding-inline: 14px;
    right: 6%;
  }

  .ql-snow .ql-tooltip a.ql-remove::before {
    padding-inline: 14px;
    left: 6%;
  }

  .ql-editor {
    padding-top: 68px !important;
  }

  .ql-editor p {
    width: 140px;
  }
}
