/* Datepicker custom styles */
.ngx-flatpickr-wrapper-input {
  display: block;
  color: black !important;
  min-width: 200px !important;
  width: 100% !important;
}

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  background-color: var(--nrb-ob-dark-color) !important;
  color: white !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border-color: var(--nrb-ob-dark-color) !important;
  font-weight: bold;
}

.flatpickr-monthDropdown-months {
  -moz-appearance: none !important;
}

.flatpickr-day:hover {
  box-shadow: 0 0 0;
  border-radius: 50px;
}

.flatpickr-day.inRange,
.flatpickr-day.today.inRange,
.flatpickr-day:hover,
.flatpickr-day:focus,
.flatpickr-day.today:hover,
.flatpickr-day.today:focus {
  cursor: pointer;
  outline: 0;
  background: var(--nrb-ob-light-color);
  border-color: var(--nrb-ob-light-color);
  color: var(--nrb-ob-dark-color);
  font-weight: bold;
  border-radius: 0;
  box-shadow:
    -5px 0 0 var(--nrb-ob-light-color),
    5px 0 0 var(--nrb-ob-light-color);
}

.flatpickr-day:hover,
.flatpickr-day.today:hover,
.flatpickr-day.today.selected:hover {
  border-radius: 50px;
  box-shadow: 0 0 0 0;
}

.flatpickr-day.inRange:hover {
  border-radius: 0;
  box-shadow:
    -5px 0 0 var(--nrb-ob-light-color),
    5px 0 0 var(--nrb-ob-light-color);
}

.flatpickr-weekday {
  font-weight: bold !important;
  color: var(--nrb-ob-dark-color) !important;
}

.flatpickr-day.today.selected {
  border-radius: 50px;
}

.flatpickr-day.today.selected.startRange,
.flatpickr-day.today.selected.startRange:hover {
  border-radius: 50px 0 0 50px;
}

.flatpickr-day.today.selected.endRange,
.flatpickr-day.today.selected.endRange:hover {
  border-radius: 0 50px 50px 0;
}

.flatpickr-day.today.selected.startRange.endRange {
  border-radius: 50px;
}

input.ngx-flatpickr-wrapper-input {
  max-width: 100%;
  padding: 10px 16px;
  border: 1px solid var(--grayF1);
  box-sizing: border-box;
  border-radius: 4px;
  /* change arrow look */
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
  background: #ffffff
    url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>")
    no-repeat;
  background-position-x: 98%;
  background-position-y: 50%;
  height: 36px;
  outline: transparent;
}

input.ngx-flatpickr-wrapper-input.active {
  background: #ffffff
    url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 0 24 24' width='24'><path d='M0 0h24v24H0z' fill='none'/><path d='M7 14l5-5 5 5z'/></svg>")
    no-repeat;
  background-position-x: 98%;
  background-position-y: 50%;
  outline: 1px solid var(--gray76) !important;
}

.flatpickr-calendar {
  margin-top: 6px;
}

.flatpickr-calendar:hover {
  border-color: black;
}

@media screen and (max-width: 880px) {
  .ngx-flatpickr-wrapper-input {
    min-width: 100% !important;
  }
}

@media screen and (max-width: 460px) {
  .flatpickr-calendar {
    left: 24px !important;
    right: 24px !important;
    margin-inline: auto !important;
  }

  .flatpickr-calendar,
  .flatpickr-days,
  .dayContainer {
    width: auto;
  }

  .dayContainer {
    min-width: auto;
    max-width: 100%;
  }
}

@media screen and (max-height: 550px) {
  .flatpickr-calendar {
    top: 72px !important;
  }
}

@media screen and (max-height: 380px) {
  .flatpickr-calendar {
    left: 0 !important;
    right: 0 !important;
    margin-inline: auto !important;
  }
}

@media screen and (max-width: 320px) {
  .ngx-flatpickr-wrapper-input {
    background: none !important;
  }

  .flatpickr-calendar {
    left: 0 !important;
    right: 0 !important;
  }
}

@media screen and (max-height: 300px) {
  .flatpickr-day {
    max-width: 34px;
    height: 34px;
    line-height: 34px;
  }
}
