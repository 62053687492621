/* You can add global styles to this file, and also import other style files */
@import "~normalize.css";
@import "vars.css";
@import "datepicker.styles.css";
@import "quill.css";

html,
body {
  font-family: "Open Sans", sans-serif;
  line-height: 20px;
  font-size: 15px;
  background-color: var(--grayFA);
  height: 100%;
  scroll-behavior: smooth;
  -webkit-tap-highlight-color: transparent;
}

a {
  color: var(--nrb-ob-dark-color);
  font-weight: 700;
}

p,
h2,
h4 {
  margin: 0;
}

a {
  color: var(--nrb-ob-dark-color);
  font-weight: 700;
}

p {
  margin: 0;
}

h3 {
  font-weight: 600;
  font-size: 22px;
  line-height: 32px;
  margin-top: 0;
}

h4 {
  font-family: "Inter", sans-serif;
  font-size: 17px;
  font-weight: 600;
  line-height: 28px;
}

.bin {
  position: relative;
  cursor: pointer;
  display: flex;
  border: none;
  background: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;

  &:hover {
    background-color: var(--grayF1);
  }

  &:disabled,
  &:disabled:hover {
    cursor: auto;
    border: none;
    stroke: var(--gray76);
    background: none;
  }
}

.card {
  border-radius: 8px;
  background-color: white;
  border: 1px solid var(--grayF1);
}

.content-tips {
  color: #2f2d2d;
  font-weight: 600;
  line-height: 1;
}

.error {
  position: absolute;
  bottom: 0;
  padding: 0 4px;
  font-size: 11px;
  color: #ba560d;
  height: 24px;
  margin-bottom: -24px;
}

.error-container {
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  z-index: 999;
  font-size: 11px;
  color: #ba560d;
}

.error-container {
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  z-index: 999;
  font-size: 11px;
  color: #ba560d;
}

.common-button {
  position: relative;
  display: flex;
  border: none;
  width: 40px;
  height: 40px;
  background-color: transparent;
  cursor: pointer;
}

.main-button,
.main-button-full-width {
  padding: 12px 16px;
  border: none;
  outline-style: none;
  font-weight: 700;
  background-color: var(--nrb-ob-color);
  color: white;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;

  &:hover {
    background-color: var(--nrb-ob-dark-color);
  }

  &:disabled,
  &:disabled:hover {
    cursor: auto;
    background-color: var(--nrb-ob-light-color);
  }
}

.main-button-full-width {
  width: 100%;
}

.notifier {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  max-width: 592px;
  padding: 24px 0;
  background-color: #fff;
  border-radius: 16px;
}

.secondary-button {
  padding: 12px 16px;
  border: none;
  outline-style: none;
  font-weight: 700;
  background-color: var(--grayFA);
  color: black;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;

  &:disabled {
    background-color: var(--grayFA);
    color: var(--gray76);
  }

  &:disabled:hover {
    background-color: var(--grayFA);
  }

  &:hover {
    background-color: var(--grayF1);
  }
}

.subtitle-box {
  margin-bottom: 22px;
}

.toast-container {
  top: 102px !important;
  right: 0 !important;
  left: 0 !important;
  width: fit-content !important;
  text-align: center !important;
  margin-inline: auto !important;
}

.ngx-toastr {
  width: fit-content !important;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.block {
  display: block;
}

.flex-column {
  flex-direction: column;
}

.align-center {
  align-items: center;
}

.align-start {
  align-items: flex-start;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}

svg {
  pointer-events: none;

  &:hover {
    cursor: pointer;
  }
}

.loading_modal {
  z-index: 9997;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.72);
  color: #ffffff;
}

.button-input {
  width: 100%;
  padding: 12px 16px;
  background: var(--grayFA);
  border: 1px solid var(--grayF1);
  box-sizing: border-box;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: black;
  outline: none;

  &:focus {
    color: black;
  }

  &:disabled,
  &::placeholder {
    color: var(--gray76);
  }
}

.creation-box-notice {
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.16px;
  color: white;
  padding: 2px 8px;
  background: var(--nrb-ob-color);
  border-radius: 10px;
  height: fit-content;
  margin: 4px 8px 0;
}

.cursor-pointer,
.close-modal-button {
  position: relative;
  display: block;
  width: 30px;
  height: 30px;
  background-color: #fff;
  border-radius: 50%;
  cursor: pointer;
  border: none;

  &:hover {
    background-color: var(--grayF1);
  }
}

.full-width {
  width: 100%;
}

.inner-svg {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
}

.info-link {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.info-url {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-decoration: underline;
  color: var(--nrb-ob-color);
}

.modal-flex {
  display: flex;
  justify-content: space-between;
  gap: 8px;
}

.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}

.page-content-box {
  position: relative;
  background: var(--grayFA);
  border: 1px solid var(--grayF1);
  box-sizing: border-box;
  border-radius: 8px;
  padding: 8px 24px;
}

.page-content-value {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  text-align: right;
  color: #000000;
  text-overflow: ellipsis;
  word-wrap: break-word;
}

.remove-actions {
  display: flex;
  justify-content: space-between;
  gap: 14px;
}

.select-wrapper {
  display: flex;
  align-items: center;
  position: relative;
  background: var(--grayFA);
  border: 1px solid var(--grayF1);
  box-sizing: border-box;
  border-radius: 4px;
  padding: 8px 6px 8px 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 46px;
  min-width: 160px;
}

.select-input {
  max-width: 120px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  background: none;
  border: 1px solid transparent;
  box-sizing: border-box;
  color: var(--gray76);
  cursor: pointer;
  outline: none;
  overflow: hidden;
  text-overflow: ellipsis;

  &::placeholder {
    color: var(--gray76);
  }
}

.title-info {
  margin-bottom: 3px;
  font-weight: 700;
  font-size: 11px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.16px;
  color: #ffffff;
  padding: 1px 8px;
  background: #000000;
  border-radius: 12px;
  margin-left: 12px;
  text-transform: uppercase;
}

.modal-content {
  max-width: 400px;
  margin: 16px 0 24px 0;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.required {
  color: var(--gray76);
}

.required-flex {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
}

.required-asterisk {
  color: var(--nrb-ob-color);
  margin-left: 4px;
}

.required-field-tips {
  line-height: 1;
}

.required-field-tips p {
  margin-bottom: 8px !important;
}

@media screen and (max-width: 720px) {
  html,
  body {
    background-color: white;
    height: auto;
  }

  .card {
    border: none;
  }

  .page-content-value {
    max-width: 330px;
  }
}

@media screen and (max-width: 700px) {
  .page-content-box {
    padding: 8px 16px;
  }
}

@media screen and (max-width: 500px) {
  .page-content-value {
    white-space: nowrap;
    overflow: hidden;
    max-width: 250px;
  }
}

@media screen and (max-width: 470px) {
  .page-content-value {
    max-width: 220px;
  }
}

@media screen and (max-width: 420px) {
  .page-content-value {
    max-width: 160px;
  }
}

@media screen and (max-width: 330px) {
  .page-content-value {
    max-width: 100px;
  }
}
