:root {
  --nrb-ob-color: #6e34e9;
  --nrb-ob-dark-color: #5b33bd;
  --nrb-ob-light-color: #bd9dff;
  --nrb-ob-lightest-color: #dbe8f9;
  --nrb-secondary-color: #53e7c3;
  --nrb-secondary-dark-color: #00d9a9;
  --light-text-color: #ddd1f9;
  --grayFA: #f7f9fb;
  --grayF1: #e6edf6;
  --gray76: #767676;
  --away-status: #ff9100;
}
